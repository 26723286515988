import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import WithValidToken from "../../common/hoc/WithValidToken";
import Spinner from "../../common/components/Spinner";
import ContractsService from "../../Services/ContractsService";
import ServicesService from "../../Services/ServicesService";
import { genetateExcelFile } from "../../utils/ExcelGenerator";
import PaymentsModal from "./PaymentsModal";
import "./Payments.css";
const Payments = (props) => {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [sugestions, setSugestions] = useState([]);
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [dates, setDates] = useState({
    from: null,
    to: null,
  });
  const [page, setPage] = useState(1);
  const [formState, setFormState] = useState({
    values: {},
  });
  const [paymentState, setPaymentState] = useState({
    values: { irpf15Retention: false, search: "" },
  });
  const history = useHistory();

  useEffect(() => {
    ContractsService.getContracts().then((response) => {
      setPayments(response);
      setLoading(false);
    });

    ServicesService.getServices().then((response) => setServices(response));
  }, []);

  useEffect(() => {
    if (formState.values.term && formState.values.value) {
      ContractsService.getContracts(
        formState.values.term,
        formState.values.value,
      ).then((response) => {
        setPayments(response);
        setLoading(false);
      });
    }
  }, [formState.values]);

  useEffect(() => {
    if (paymentState.values.search && paymentState.values.search !== "") {
      ContractsService.getContractUserInfo({
        term: "name",
        value: paymentState.values.search,
      }).then((response) => {
        const uniqueNames = new Set();
        const groupedData = response
          .filter((contract) => {
            const isUnique = !uniqueNames.has(contract.name);
            uniqueNames.add(contract.name);
            return isUnique;
          })
          .map((contract) => {
            return {
              name: contract.name,
              dni: contract.dni,
              phone: contract.phone,
              email: contract.email,
            };
          });

        setSugestions(groupedData);
      });
    } else {
      setSugestions([]);
    }
  }, [paymentState.values]);

  const handleNewPayment = (event) => {
    event.persist();
    console.log(event.target.value);
    setPaymentState((paymentstate) => ({
      ...paymentstate,
      values: {
        ...paymentstate.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const validateData = (datos) => {
    const { name, email, phone, dni, service_id, price } = datos;

    if (!name) {
      alert("Por favor, ingresa un nombre.");
      return false;
    }

    //const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //if (!regexEmail.test(email)) {
    //  alert("Por favor, ingresa un email válido.");
    //  return false;
    //}

    const regexPhone = /^\d+$/;
    if (!regexPhone.test(phone)) {
      alert("El teléfono debe contener solo números.");
      return false;
    }

    if (!dni) {
      alert("Por favor, ingresa un DNI/NIE/NIF válido.");
      return false;
    }

    if (!service_id) {
      alert("Selecciona un servicio");
      return false;
    }
    if (isNaN(price)) {
      alert("El precio deben ser numéricos.");
      return false;
    }

    return true;
  };

  const handleSaveInvoice = (event) => {
    event.preventDefault();
    setSending(true);
    const data = paymentState.values;
    if (validateData(data)) {
      setLoading(true);
      ContractsService.saveNewInvoice(data)
        .then((response) => {
          console.log(response);
          setSending(false);
          setShowModal(false);
          ContractsService.getContracts().then((response) => {
            setPayments(response);
            setLoading(false);
          });
          setPaymentState({
            values: { irpf15Retention: false },
          });
          window.location.reload();
        })
        .catch((error) => console.error(error));
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);

      ContractsService.getContracts(
        formState.values.term,
        formState.values.value,
        page - 1,
      ).then((response) => {
        setPayments(response);
        setLoading(false);
      });
    }
  };

  const handleSelectedUser = (event) => {
    const selectedUser = event.target.dataset.email;
    const user = sugestions.find((s) => s.email === selectedUser);
    setPaymentState({ values: user });
    setSugestions([]);
  };

  const handleNextPage = () => {
    if (page >= 0 && page < payments.pages) {
      setPage(page + 1);
      ContractsService.getContracts(
        formState.values.term,
        formState.values.value,
        page + 1,
      ).then((response) => {
        setPayments(response);
        setLoading(false);
      });
    }
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleDatesChange = (event) => {
    event.persist();
    setDates((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleGenerateExcel = () => {
    ContractsService.getPayments(dates.from, dates.to).then((response) => {
      console.log(response);
      const excelRows = response.contracts.map((contract) => ({
        Fecha: new Intl.DateTimeFormat("es-ES").format(
          new Date(contract.created_at),
        ),
        "Nº Factura": contract.invoice_number,
        Concepto: contract.service_id.name,
        Nombre: contract.name,
        NIF: contract.dni,
        Importe: contract.stripe_amount / 100,
        "RET. 15 %": contract.irpf15Retention ? contract.irpf15Amount : "",
        IVA: "",
        Total: contract.irpf15Retention
          ? contract.stripe_amount / 100 - contract.irpf15Amount
          : contract.stripe_amount / 100,
      }));
      console.log(excelRows);
      genetateExcelFile(excelRows, "pagos");
    });
  };

  const showQuestionary = (event) => {
    const contractId = event.target.dataset.id;
    const clientName = event.target.dataset.name;
    history.push({
      pathname: `contract/${contractId}`,
      state: { clientName },
    });
  };

  const renderPayments = () => {
    if (payments && payments.contracts.length === 0) {
      return;
    }
    return payments.contracts.map((payment) => (
      <tr key={payment._id}>
        <td>
          {(payment.stripe_status === "succeeded" ||
            payment.stripe_status === "free") && (
            <Link
              to={{
                pathname: `/messages/${payment._id}`,
                state: {
                  serviceId: payment.service_id._id,
                  contractId: payment._id,
                  serviceName: payment.service_id.name,
                  userName: payment.name,
                },
              }}
              className="link"
            >
              <i class="zwicon-mail" style={{ fontSize: "20px" }}></i>
            </Link>
          )}
        </td>
        <td>
          {payment.questionary_completed && (
            <button
              className="circle-button"
              data-id={payment._id}
              data-name={payment.name}
              onClick={showQuestionary}
            >
              <i class="zwicon-comment"></i>
            </button>
          )}
        </td>
        <td>{payment.service_id.name}</td>
        <td>
          <span
            className={
              payment.stripe_status === "succeeded"
                ? "payment-succeeded"
                : "payment-incomplete"
            }
          >
            {payment.stripe_status}
          </span>
        </td>
        <td>{payment.order_id}</td>
        <td>{payment.name}</td>
        <td>{payment.email}</td>
        <td>{payment.phone}</td>
        <td>{payment.dni}</td>
        <td>
          {new Intl.DateTimeFormat("es-ES").format(
            new Date(payment.created_at),
          )}
        </td>
        <td>{payment.invoice_number}</td>
        <td>{payment.stripe_amount / 100}</td>
        <td>{payment.coupon}</td>
      </tr>
    ));
  };

  return (
    <div className="users-container" style={{ paddingRight: "20px" }}>
      <div className="title">
        <h1>Pagos</h1>
        <button
          className="circle-button add-services"
          title="agregar nuevo pago"
          onClick={() => {
            setShowModal(true);
          }}
        >
          +
        </button>
      </div>
      <div className="forms-container">
        <form className="search-form excel-invoice">
          <div className="form-field">
            <label htmlFor="streaming_course.streaming_date">
              <b>Buscar por:</b>
            </label>
            <br />
            <select name="term" onChange={handleChange} defaultValue="">
              <option value="" disabled hidden>
                Buscar por...
              </option>
              <option value="order_id">número de orden</option>
              <option value="invoice_number">número de factura</option>
              <option value="email">email</option>
              <option value="phone">teléfono</option>
              <option value="dni">DNI/NIE</option>
              <option value="email">email</option>
              <option value="name">nombre</option>
              <option value="stripe_status">status</option>
            </select>
            <input
              id="value"
              name="value"
              type="text"
              defaultValue=""
              placeholder="Termino de busqueda"
              onChange={handleChange}
            />
          </div>
        </form>
        <form className="excel-invoice">
          <div className="form-field">
            <label htmlFor="streaming_course.streaming_date">
              <b>Desde</b>
            </label>
            <br />
            <input
              type="date"
              placeholder="Fecha"
              name="from"
              onChange={handleDatesChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="streaming_course.streaming_date">
              <b>Hasta</b>
            </label>
            <br />
            <input
              type="date"
              placeholder="Fecha"
              name="to"
              onChange={handleDatesChange}
            />
          </div>
          <div className="form-field">
            <button
              type="button"
              className="button"
              onClick={handleGenerateExcel}
            >
              Generar Excel
            </button>
          </div>
        </form>
      </div>
      {loading && <Spinner />}
      {!loading && (
        <div
          style={{
            maxWidth: "1185px",
            overflowX: "scroll",
            paddingLeft: "20px",
            paddingRight: "20xp",
          }}
        >
          <table className="payment-table">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Servicio</th>
                <th>Estado</th>
                <th>Orden</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>DNI/NIE</th>
                <th>Fecha</th>
                <th>Factura</th>
                <th>Monto</th>
                <th>Cupón</th>
              </tr>
            </thead>
            <tbody>{renderPayments()}</tbody>
          </table>
        </div>
      )}
      <div className="paginator-container">
        <button className="circle-button" onClick={handlePreviousPage}>
          {"<"}
        </button>
        <button className="circle-button" onClick={handleNextPage}>
          {">"}
        </button>
      </div>
      <PaymentsModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleChange={handleNewPayment}
        sugestions={sugestions}
        handleSelectedUser={handleSelectedUser}
        paymentState={paymentState.values}
        services={services}
        handleSaveInvoice={handleSaveInvoice}
        sending={sending}
      />
    </div>
  );
};

export default WithValidToken(Payments);
